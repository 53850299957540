import "owl.carousel";
export function getLogos(queryUrl, pathLogo) {
  $.ajax({
    url: queryUrl,
    method: "POST",
  })
    .done(function (data) {
      let response = JSON.parse(data);
      let $clientList = $("<div/>").addClass("clients-list owl-carousel");
      let logos = "";
      response.forEach(function (logo) {
        logos +=
          '<div class="clients-list__item"><img src="' +
          pathLogo +
          logo[1] +
          '" alt="" class="client-list__logo" /></div>';
      });

      $clientList.append(logos);
      $(".clients__container").append($clientList);
      $(".clients-list").owlCarousel({
        margin: 26,
        items: 4,
        loop: true,
        slideBy: 2,
        dots: false,
        lazyLoad: true,
        autoplay: true,
        responsive: {
          768: {
            items: 5,
            margin: 64,
            slideBy: 4,
          },
          1024: {
            items: 6,
            margin: 64,
            slideBy: 4,
          },
          1200: {
            items: 8,
            margin: 64,
            slideBy: 4,
          },
        },
      });
    })
    .fail(function (error) {
      console.log("Get Logos - Error : " + erreur);
    });
}
