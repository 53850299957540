export function getReviewsInfos(place, status) {
  let authorizedValues = [];
  for (let i = 0; i <= 5; i += 0.5) {
    authorizedValues.push(i);
  }

  if (status == google.maps.places.PlacesServiceStatus.OK) {
    let reviews = place.user_ratings_total;
    let rating = place.rating;
    let closestScore = authorizedValues.reduce((a, b) => {
      return Math.abs(b - rating) < Math.abs(a - rating) ? b : a;
    });
    let starsValue = (closestScore * 100) / 5;

    $("#reviewsInfos").html(rating + "/5 - " + reviews + " avis");
    $(".rating-stars").addClass("rating-stars--score-" + starsValue);
  }
}
