export function setFormEvents(page = "main") {
  let $selectClient = $('input[name="typeClient"]');
  let $selectPro = $("#choixProfessionnel");
  let $wrapperPro = $(".evtpro");
  let $selectPart = $("#choixParticulier");
  let $wrapperPart = $(".evtpart");
  let $selectRestauration = $("#categorieRestauration");
  let $submitButton = $("#btnSubmit");

  $selectPro.on({
    focus: function () {
      $(this).prev().hide();
    },

    change: function () {
      if (!$(this).val()) {
        $selectPro.children("option:eq(0)").prop("selected", true);
        $selectPart.children("option:eq(0)").prop("selected", true);
        $selectRestauration.children("option:eq(0)").prop("selected", true);
      }

      loadRestauration("choixProfessionnel");
    },
  });

  $selectPart.on({
    focus: function () {
      $(this).prev().hide();
    },

    change: function () {
      if (!$(this).val()) {
        $selectPro.children("option:eq(0)").prop("selected", true);
        $selectPart.children("option:eq(0)").prop("selected", true);
        $selectRestauration.children("option:eq(0)").prop("selected", true);
      }

      loadRestauration("choixParticulier");
    },
  });

  $selectClient.on("change", function () {
    let $this = $(this);
    if ($this.is(":checked")) {
      let value = $this.val();

      // if is pro
      if (value == 1) {
        $wrapperPart.hide();
        $selectPart.prop("disabled", true);
        $selectPro.prop("disabled", false);
        $wrapperPro.show().find("button").hide();
        $selectPro.change();
      }

      // if is part
      if (value == 2) {
        $wrapperPro.hide();
        $selectPro.prop("disabled", true);
        $selectPart.prop("disabled", false);
        $wrapperPart.show().find("button").hide();
        $selectPart.change();
      }
    }
  });

  $selectRestauration.on("focus", function () {
    $(this).prev().hide();
  });

  $submitButton.on("click", function () {
    submitSearch(page);
  });
}

export function initForm() {
  let $selectClient = $('input[name="typeClient"]:checked').val();
  let $selectPro = $("#choixProfessionnel");
  let $selectPart = $("#choixParticulier");

  if ($selectClient == 1) {
    $selectPro.change();
  } else {
    $selectPart.change();
  }
}

function loadRestauration(type) {
  let $selectRestauration = $("#categorieRestauration");
  let $wrapperRestauration = $("#colRest");
  let $submitButton = $("#btnSubmit");

  //let tableaur = decodeURIComponent(
//    $("#" + type + " option:selected").prop("id"),
//  ).split("-")[1];
  let tableaur = $("#" + type + " option:selected").prop("id").split("=")[1].split("-");
  if (!tableaur) return false;
  let optionName =
    type == "choixProfessionnel" ? "catRestREF" : "catRestREFPart";
  $selectRestauration.find("option").remove();

  $('select[name="' + optionName + '"] option').each(function () {
    let $this = $(this);
    let numr =  $this.attr("id").split("_")[1];

    if (tableaur.indexOf(numr) != -1) {
      let option = new Option($this.text(), $this.val());
      option.innerHTML = $this.text();
      $selectRestauration.append(option);
      option.setAttribute("id", "rest_" + $this.attr("id").split("_")[1]);
    }
  });

  if ($selectRestauration.find("option").length == 0) {
    $submitButton.html("Complétez le formulaire");
    $selectRestauration.prop("disabled", true);
    $wrapperRestauration.hide();
  } else {
    $submitButton.html("Rechercher");
    $selectRestauration.prop("disabled", false);
    $wrapperRestauration.show();
  }

  // $("#choixProfessionnel").removeProp("disabled");
  // $("#choixParticulier").removeProp("disabled");
  // $selectRestauration.removeProp("disabled");
}

export function submitSearch(page = "main") {
  let $inputParticipants = $("#nbpart");
  let $nbParticipants = $inputParticipants.val();
  let $selectClient = $('input[name="typeClient"]:checked').val();

  let $selectPro = $("#choixProfessionnel");
  let $selectProVal = $selectPro.val();

  let $selectPart = $("#choixParticulier");
  let $selectPartVal = $selectPart.val();

  let $selectRestauration = $("#categorieRestauration");
  let $selectRestaurationVal = $selectRestauration.val();

  // Nombre de participants
  if ($nbParticipants == "") {
    $("#partnumber").val(100 + " personnes");
    $inputParticipants.val(100);
  }

  // Url parameters
  let nombre = lpad($inputParticipants.val(), "0", 4);
  let dateEvt = $("#choixDate").val();
  if (page == "parcours-bis") {
    if (!dateEvt || dateEvt == "") dateEvt = "00/00/0000";
    dateEvt = dateEvt.split(" ");
    dateEvt = dateEvt[0].split("/").join("-");
  } else {
    if (!dateEvt || dateEvt == "") dateEvt = "Jamais 00/00/0000";
    dateEvt = dateEvt.split(" ");
    dateEvt = dateEvt[1].split("/").join("-");
  }

  let categorie = $selectRestaurationVal
    ? lpad($selectRestaurationVal, "0", 4)
    : "";
  let typeClient = lpad($selectClient, "0", 4);
  let evtPro = $selectProVal ? lpad($selectProVal, "0", 4) : "0000";
  let evtPart = $selectPartVal ? lpad($selectPartVal, "0", 4) : "0000";
  let departement = page == "main" ? lpad("00", "0", 4) : lpad("14", "0", 4);
  let url = getResultsUrl();

  if (page == "main") {
    window.location.href =
      url +
      "/" +
      nombre +
      categorie +
      typeClient +
      evtPro +
      evtPart +
      departement +
      "/00/88/" +
      dateEvt;
  } else {
    let roomId = $(".search-form").data("roomid").toString();
    let customParam = getCustomParam();
    window.location.href =
      url +
      "/" +
      nombre +
      categorie +
      typeClient +
      evtPro +
      evtPart +
      departement +
      lpad(roomId, "0", 4) +
      customParam +
      dateEvt;
  }
}
